import { defineNuxtPlugin, useCookie, useRoute, useRuntimeConfig, useState } from "#imports";
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public.studio || {};
  const route = useRoute();
  const previewToken = useCookie("previewToken", { sameSite: "none", secure: true });
  const storage = useState("studio-client-db", () => null);
  async function initializePreview() {
    const useStudio = await import("../composables/useStudio.js").then((m) => m.useStudio);
    const { mountPreviewUI, initiateIframeCommunication } = useStudio();
    mountPreviewUI();
    initiateIframeCommunication();
  }
  if (runtimeConfig.apiURL) {
    if (Object.prototype.hasOwnProperty.call(route.query, "preview") && !route.query.preview) {
      return;
    }
    if (!route.query.preview && !previewToken.value) {
      return;
    }
    if (route.query.preview) {
      previewToken.value = String(route.query.preview);
    }
    window.sessionStorage.setItem("previewToken", String(previewToken.value));
    window.sessionStorage.setItem("previewAPI", typeof route.query.staging !== "undefined" ? "https://dev-api.nuxt.studio" : runtimeConfig.apiURL);
    nuxtApp.hook("content:storage", (_storage) => {
      storage.value = _storage;
    });
    nuxtApp.hook("app:mounted", async () => {
      await initializePreview();
    });
  }
});
