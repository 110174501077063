<script lang="ts" setup>
interface Link {
  to?: string
  label: string
  external?: boolean
  badge?: string
  children?: Link[]
}

// collapseMap is a map of links that are collapsed
const isCollapsed = ref(new Map())

/**
 * Add links here
 */
const links: Link[] = [
  {
    to: '/luft-til-vand-varmepumpe',
    label: 'Luft til vand-varmepumpe',
    external: false,
  },
  {
    to: '/solceller',
    label: 'Solceller',
  },
  {
    to: '/boligforeninger',
    label: 'Foreninger',
  },
  {
    to: '/erhverv',
    label: 'Erhverv',
  },
  {
    label: 'Om Amalo',
    children: [
      {
        to: '/om-amalo',
        label: 'Om Amalo',
      },
      {
        to: '/hvad-er-prisen-luft-til-vand-varmepumpe',
        label: 'Pris',
      },
      {
        to: '/partner',
        label: 'Partner',
      },
      {
        to: '/viden',
        label: 'Spørgsmål & Svar',
      },
      {
        to: '/news',
        label: 'Blog',
      },
      {
        to: '/bliv-en-del-af-amalo',
        label: 'Ledige stillinger',
      },
      {
        to: '/garanti-service',
        label: 'Garanti & Service',
      },
      {
        to: '/handelsbetingelser',
        label: 'Handelsbetingelser',
      },
      {
        to: 'https://min.amalo.dk/login',
        label: 'Log ind',
        external: true,
      },
    ],
  },
]

const isMobileMenuOpen = ref(false)
</script>

<template>
  <header class="bg-white">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 xl:px-8 border-b border-blue-300 xl:border-b-0 " aria-label="Global">
      <div class="flex xl:flex-1">
        <NuxtLink to="/" class="-m-1.5 p-1.5">
          <span class="sr-only">Amalo ApS</span>
          <NuxtImg class="h-8 w-auto" src="/images/amalo-logo.svg" alt="Amalo Logo" />
        </NuxtLink>
      </div>
      <div class="flex xl:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="isMobileMenuOpen = true">
          <span class="sr-only">Åben menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
      <div class="hidden xl:flex xl:gap-x-12">
        <template
          v-for="link in links"
          :key="link.label"
        >
          <NuxtLink
            v-if="!link.children"
            :external="link.external"
            :to="link.to"
            class="text-sm font-semibold leading-6 hover:text-blue-600"
            active-class="text-blue-500"
          >
            {{ link.label }}
            <span
              v-if="link.badge"
              class="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
            >
              {{ link.badge }}
            </span>
          </NuxtLink>

          <DropdownMenuRoot v-else>
            <DropdownMenuTrigger class="text-sm font-semibold leading-6 text-blue-900 flex items-center">
              {{ link.label }}
              <Icon name="heroicons:chevron-down-20-solid" class="text-lg ml-2" />
            </DropdownMenuTrigger>

            <DropdownMenuPortal>
              <DropdownMenuContent :side-offset="5" class="rounded-md bg-white shadow-lg ring-1 ring-blue-900/5 p-4 min-w-[220px] cursor-pointer data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade">
                <DropdownMenuItem
                  v-for="children in link.children"
                  :key="children.label"
                  class="rounded-lg p-4 text-sm leading-6 hover:bg-blue-200"
                  @select="() => navigateTo(children.to, { external: children.external })"
                >
                  {{ children.label }}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenuRoot>
        </template>
      </div>
      <div class="hidden xl:flex xl:flex-1 xl:justify-end space-x-4">
        <NuxtLink to="https://min.amalo.dk/login" external class="inline-flex items-center gap-x-2 rounded-md bg-blue-200 border border-blue-300 px-3.5 py-2.5 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          Log ind

          <Icon name="heroicons-outline:arrow-right-on-rectangle" class="w-4 h-4" />
        </NuxtLink>

        <NuxtLink to="/faa-et-gratis-tilbud-indenfor-24-timer" class="inline-flex items-center gap-x-2 rounded-md  bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          Få et tilbud
        </NuxtLink>
      </div>
    </nav>

    <!-- Mobile menu, show/hide based on menu open state. -->
    <div v-if="isMobileMenuOpen" class="xl:hidden" role="dialog" aria-modal="true">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 z-10" />
      <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-blue-900/10">
        <div class="flex items-center justify-between">
          <NuxtLink to="/" class="-m-1.5 p-1.5">
            <span class="sr-only">Amalo ApS</span>
            <NuxtImg class="h-8 w-auto" src="/images/amalo-logo.svg" alt="Amalo Logo" />
          </NuxtLink>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="isMobileMenuOpen = false">
            <span class="sr-only">Luk menu</span>
            <Icon name="heroicons-outline:x-mark" class="h-6 w-6" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <template
                v-for="link in links"
                :key="link.label"
              >
                <NuxtLink
                  v-if="!link.children"
                  :external="link.external"
                  :to="link.to"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue-900 hover:bg-blue-200"
                  @click="isMobileMenuOpen = false"
                >
                  {{ link.label }}
                  <span
                    v-if="link.badge"
                    class="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                  >
                    {{ link.badge }}
                  </span>
                </NuxtLink>

                <CollapsibleRoot v-else @update:open="isCollapsed.set(link, !isCollapsed.get(link))">
                  <CollapsibleTrigger class="-mx-3 flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-blue-900 hover:bg-blue-200">
                    {{ link.label }}
                    <Icon name="heroicons-outline:chevron-down" class="h-5 w-5 flex-none" :class="{ 'rotate-180': isCollapsed.get(link) }" />
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <NuxtLink
                      v-for="children in link.children"
                      :key="children.label"
                      :external="children.external"
                      :to="children.to"
                      class="block rounded-lg py-2 pl-6 pr-3 text-sm font-medium leading-7 text-blue-900 hover:bg-blue-200"
                      @click="isMobileMenuOpen = false"
                    >
                      {{ children.label }}
                      <span
                        v-if="children.badge"
                        class="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                      >
                        {{ children.badge }}
                      </span>
                    </NuxtLink>
                  </CollapsibleContent>
                </CollapsibleRoot>
              </template>
            </div>
            <div class="py-6">
              <NuxtLink to="https://min.amalo.dk/login" external class="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                Log ind
                <Icon name="heroicons-outline:arrow-right-on-rectangle" class="w-4 h-4" />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
