<script setup lang="ts">
const config = useRuntimeConfig()

const device = useDevice()

if (config.public.isProd && !device.userAgent.includes('Morningscore Bot')) {
  useHead({
    script: [
      { 'src': 'https://consent.cookiebot.com/uc.js', 'async': true, 'defer': false, 'id': 'Cookiebot', 'data-cbid': '03476f72-9748-416c-93a8-b7f2a8a3b1a7', 'type': 'text/javascript' },
      { src: 'https://js.hs-scripts.com/6517230.js', async: true, defer: false, id: 'hs-script-loader', type: 'text/javascript' },
    ],
  })
}

useSchemaOrg([
  defineAggregateRating({
    name: 'Amalo ApS',
    ratingValue: 4.3,
    reviewCount: 130,
  }),
])
</script>

<template>
  <div>
    <div
      v-if="config.public.isDev"
      :class="{
        'bg-red-600': config.public.amalo.baseURL.env === 'production',
        'bg-yellow-600': config.public.amalo.baseURL.env === 'staging',
        'bg-blue-600': config.public.amalo.baseURL.env === 'development',
      }"
      class="flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
    >
      <p class="text-sm leading-6 text-white">
        <strong class="font-semibold">NODE_ENV: {{ config.public.amalo.baseURL.env }}</strong>
      </p>
      <p class="text-sm leading-6 text-white">
        <strong class="font-semibold">APP_ENV: {{ config.public.isDev ? 'development' : 'production' }}</strong>
      </p>
      <div class="flex flex-1 justify-end" />
    </div>

    <div v-if="config.public.isMaintenance.enabled" class="flex items-center gap-x-6 bg-yellow-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p class="text-sm leading-6 text-white">
        <strong class="font-semibold">{{ config.public.isMaintenance.title }}</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>{{ config.public.isMaintenance.subtitle }}
      </p>
      <div class="flex flex-1 justify-end" />
    </div>

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
