import { captureException, getClient, getTraceMetaTags } from "@sentry/core";
export function extractErrorContext(errorContext) {
  const ctx = {};
  if (!errorContext) {
    return ctx;
  }
  if (errorContext.event) {
    ctx.method = errorContext.event._method;
    ctx.path = errorContext.event._path;
  }
  if (Array.isArray(errorContext.tags)) {
    ctx.tags = errorContext.tags;
  }
  return ctx;
}
export function addSentryTracingMetaTags(head) {
  const metaTags = getTraceMetaTags();
  if (metaTags) {
    head.push(metaTags);
  }
}
export function reportNuxtError(options) {
  const { error, instance, info } = options;
  const metadata = {
    info
    // todo: add component name and trace (like in the vue integration)
  };
  if (instance?.$props) {
    const sentryClient = getClient();
    const sentryOptions = sentryClient ? sentryClient.getOptions() : null;
    if (sentryOptions?.attachProps && instance.$props !== false) {
      metadata.propsData = instance.$props;
    }
  }
  setTimeout(() => {
    captureException(error, {
      captureContext: { contexts: { nuxt: metadata } },
      mechanism: { handled: false }
    });
  });
}
